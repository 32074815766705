<template>
  <div>
    <AdminiStration />
  </div>
</template>

<script>
import  AdminiStration from "@/components/admin/AdminiStration";

export default {
  data() {
    return {
      data: [],
    };
  },
   components: {
    AdminiStration,
  },
  metaInfo: {
    title: "Administratie bekijken en beheren | flexplekken in Nederland ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>
