<template>
  <div>
    <v-container style="padding-top: 4rem">
      <div style="margin-bottom:10px">
        <v-btn color="secondary" >
          Ga terug
        </v-btn>
      </div>
      <div class="row">
        <div class="col-12 col-lg-3 mb-4">
          <div>
            <div class="link-box">
              <v-icon large color="green darken-2"> mdi-cart-variant </v-icon>
              <h2>{{ totalOpenInvoices.aantal }}</h2>
              <p class="link-box-subtitle">Aantal Openstaande facturen</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3 mb-4">
          <div class="link-box">
            <v-icon large color="error"> mdi-close-octagon-outline </v-icon>
            <h2>{{ totalLateInvoices.aantal }}</h2>

            <p class="link-box-subtitle">Aantal telaat betaalde facturen</p>
          </div>
        </div>
        <div class="col-12 col-lg-3 mb-4">
          <div class="link-box">
            <v-icon large color="primary"> mdi-cash </v-icon>
            <h2>{{ totalReminderInvoices.aantal }}</h2>
            <p class="link-box-subtitle">Aantal verstuurde herinneringen</p>
          </div>
        </div>
        <div class="col-12 col-lg-3 mb-4">
          <div class="link-box">
            <v-icon large color="primary"> mdi-cash </v-icon>
            <h2>{{ totalScheduledInvoices.aantal }}</h2>
            <p class="link-box-subtitle">Aantal openstaand geplande facturen</p>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>

import api from "../api/apiAdmin";
export default {
  
  data() {
    return {
      totalLateInvoices: [],
      totalOpenInvoices: [],
      totalReminderInvoices: [],
      totalScheduledInvoices: [],
    };
  },

  async mounted() {
    api.getTotalOpenInvoices().then((data) => {
      this.totalOpenInvoices = data;
    });

    api.getTotalLateInvoices().then((data) => {
      this.totalLateInvoices = data;
    });

    api.getTotalReminderInvoices().then((data) => {
      this.totalReminderInvoices = data;
    });

    api.getTotalScheduledInvoices().then((data) => {
      this.totalScheduledInvoices = data;
    });
  },

};
</script>

<style scoped>
.router-link {
  color: black;
  text-decoration: none;
}
.link-box {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 5px 8px 0 rgba(0, 0, 0, 0.05);
  padding: 20px;
  font-size: 16px;
  text-align: center;
  /* min-height:100%; */
}
.link-box p {
  margin: 0px 0px 10px 0px;
}
.link-box img {
  height: 40px;
  margin-bottom: 10px;
}
.link-box-title {
  color: black;
  font-weight: bold;
  font-size: 18px;
}
.link-box-subtitle {
  color: black;
  font-size: 18px;
  margin-bottom: 10px;
}
</style>
