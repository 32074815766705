import axios from 'axios'

const API = axios.create({
  baseURL: window.location.hostname.includes("flexvastgoedcrm")
  ? "https://server.kantoorflex.nl/api/rest/v2"
  : "https://server.kantoorflex.nl/api/rest/v2",
  timeout: 30000,
});


export default {
 //Administration APIS
   async getTotalReminderInvoices() {
    try {
      const response = await API.get(`/moneybird/invoices/reminder`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },

  async getTotalScheduledInvoices() {
    try {
      const response = await API.get(`/moneybird/invoices/scheduled`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  async getTotalOpenInvoices() {
    try {
      const response = await API.get(`/moneybird/invoices`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  async getTotalLateInvoices() {
    try {
      const response = await API.get(`/moneybird/invoices/late`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },

 
}